import { render, staticRenderFns } from "./AddEntityReationshipDataWidget.vue?vue&type=template&id=74bf2908&scoped=true"
import script from "./AddEntityReationshipDataWidget.vue?vue&type=script&lang=js"
export * from "./AddEntityReationshipDataWidget.vue?vue&type=script&lang=js"
import style0 from "./AddEntityReationshipDataWidget.vue?vue&type=style&index=0&id=74bf2908&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74bf2908",
  null
  
)

export default component.exports